//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import location from "@/assets/storecomment/location.png";
import star from "@/assets/storecomment/star.png";
import refresh from "@/assets/storecomment/refresh.png";
import unstar from "@/assets/storecomment/unstar.png";
import { LocalStorage } from "quasar";
export default {
  name: "Index",
  data() {
    return {
      location,
      star,
      refresh,
      unstar,
      mobile: "",
      list: [],
      bottomDistance: 10,
      pageSize: 10,
      pageIndex: 1,
      total: 1,
      storeName: null,
      storeId: null,
      loading: true,
      stores: [],
      storeOption: [],
      currentStore: "",
    };
  },

  created() {
    const that = this;
    let temp = LocalStorage.getItem('userOwnerShops');
    let shopNames = LocalStorage.getItem('ownerShopNames');
  
    if (temp && temp != "" && temp != "null") {
      this.stores = temp.split(",");
      let storeNames = shopNames.split(",");
      this.currentStore = this.stores[0];
      this.stores.forEach((item, i) => {
        that.storeOption.push({ text: item + ' ' + storeNames[i], value: item });
      });
    } else {
      this.storeOption = [];
    }
    this.getList();
    window.document.title = "门店订单评价";
  },
  activated() {
    window.document.title = "门店订单评价";
  },
  mounted() {
    window.document.title = "门店订单评价";
  },
  methods: {
    storeChange(value) {
      console.log(value);
      this.getList();
    },
    handleScroll() {
      let scrollEle = this.$el;
      let bDistance =
        scrollEle.scrollHeight - scrollEle.scrollTop - scrollEle.clientHeight;
      if (bDistance < this.bottomDistance) {
        this.onReachBottom();
      }
    },
    onReachBottom() {
      if (this.pageIndex < this.total) {
        this.pageIndex += 1;
        this.getList(false);
      }
    },
    searchMobile() {
      this.getList();
    },
    getList(reload = true) {
      this.loading = true;
      const that = this;
      if (reload) {
        this.pageIndex = 1;
      }
      // this.axios.post('/consumer/v1/wx/cp/order/commentList', {
      this.axios
        .post("/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/order/commentList", {
          pager: {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            rowsCount: 0,
            start: 0,
          },
          queryMap: {
            storeid: this.currentStore,
            tel: this.mobile,
            // "tel" :'18196632597',
            // "status": "0"
          },
        })
        .then((res) => {
          if (res && res.data && res.data.data && res.data.data.list) {
            that.total = Math.ceil(
              res.data.data.page.rowsCount / that.pageSize
            );
            if (res.data.data.list.length > 0) {
              that.storeName = res.data.data.list[0].storename;
              that.storeId = res.data.data.list[0].storeid;
            }
            if (!reload) {
              that.list = that.list.concat(res.data.data.list);
            } else {
              that.list = res.data.data.list;
            }
          } else {
            that.list = [];
          }
          that.list.forEach((item) => {
            item.service = Number(item.service);
            item.shop = Number(item.shop);
            item.guide = Number(item.guide);
          });
          that.loading = false;
        });
    },
  },
};
